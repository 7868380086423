import type { Branches } from "~/types/directus-collections";
import { fetchBranchBySlug } from "~/utils/branchUtils";

export default defineNuxtRouteMiddleware(async (to, _from) => {
  // --- Check if the user is a president of the branch
  let branch: Branches | null = null;

  const { slug } = to.params;
  if (typeof slug === "string" && slug) {
    branch = await fetchBranchBySlug(slug, { fields: ["id"] });
  }

  if (!branch || !isBranchPresident(branch)) {
    return navigateTo("/account");
  }
});
